import React from 'react';
import PropTypes from 'prop-types';

// import Text from './components/Text/Text';
// import Table from './components/Table/Table';
import BreadcrumbsSemanticMarkup from '@components/BreadcrumbsMarkup/BreadcrumbsMarkup';
import { dateToString } from '@helpers';
import style from './TermsService.module.scss';

// const renderPageContent = (body) => {
//   const content = body.map((data) => {
//     return data.slice_type === 'text' ? (
//       <Text {...data} />
//     ) : (
//       <Table fields={data.items} />
//     );
//   });

//   return content;
// };

const TermsServicePage = ({ pageTitle, date, canonical }) => {
  return (
    <div className={style.page}>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.date}>{dateToString(date)}</div>
        </div>
        <h1 className={style.title}>Autoblock testing iframe</h1>
        <iframe
          src="https://www.youtube.com/embed/4Bm-wn-ILoo"
          width="600px"
          height="400px"
          frameBorder="0"
        />


        <h1 className={style.title}>Autoblock testing calendly</h1>

        <iframe
          src="https://calendly.com/secure-privacy/45min"
          width="100%"
          height="900"
          frameBorder="0"
        ></iframe>


        <h1 className={style.title}>Autoblock testing video tags</h1>

        <video controls="true" src="https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4">
        </video>


        {/* {renderPageContent(pageContent)} */}
        <BreadcrumbsSemanticMarkup
          pageTitle={pageTitle.text}
          pageUrl={canonical.text}
        />
      </div>
    </div>
  );
};

TermsServicePage.propTypes = {
  pageContent: PropTypes.array.isRequired,
  pageTitle: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  canonical: PropTypes.object.isRequired,
};

export default TermsServicePage;
